html, body {
  font-family: "Montserrat", sans-serif;
  line-height: 1.5;
  font-size: 16px;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}   
body {
  margin: 0;
  color: $font-color;
  background-color: $bg-color;
  overflow-x: hidden;
}

a, .text-primary {
  color: $primary-color;
}
a {
  text-decoration: none;
}
a:hover {
  color: darken($primary-color, 10%);
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
  margin-bottom: 0.8rem;
  font-weight: bold;
  color: $font-color-lead;
}
h1, h2 {
  font-size: 2.5rem;
  line-height: 1;
}
h3 {
  font-size: 2rem;
}
h4 {
  font-size: 1.75rem;
}
h5 {
  font-size: 1.5rem;
}
h6 {
  font-size: 1.25rem;
}
p {
  margin-bottom: 1rem;
}

hr.small,
hr.small-centered {
  margin: 1.5rem auto;
  width: 4rem;
  height: 3px;
  background-color: $primary-color;
  border: none;
  opacity: 1;
}

hr.small {
  margin: 1.5rem 0;
}

img.img-centered {
  display: block;
  max-width: 100%;
  margin: 0 auto;
}

.text-content img.img-centered {
  margin-top: 2.5em;
  margin-bottom: 2.5em;
}

.r {text-align: right;}
[dir="rtl"] .r {text-align: left;}
.c {text-align: center;}
.l {text-align: left;}
[dir="rtl"] .l {text-align: right;}