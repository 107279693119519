.blog-posts .row > div {
  padding: 1rem;
}

.blog-post {
  background: $bg-color-darker;
  border-radius: 2.75rem;
  overflow: hidden;
  height: 100%;
  display: flex;
  flex-flow: column nowrap;

  > .cover {
    display: block;
    width: 100%;
    aspect-ratio: 4 / 3;
    object-fit: cover;
  }

  > .preview {
    flex-grow: 1;
    display: flex;
    flex-flow: column nowrap;
    padding: 1rem;
    > .date {
      font-size: 0.8em;
      margin-bottom: 1em;
    }
    > p {
      flex-grow: 1;
    }
    > .btn {
      display: block;
      width: 100%;
    }
  }
}

.blog-post-header.inverse {
  color: $font-color-contrast;
  h1 {
    color: $font-color-contrast;
  }
}

.blog-post-content {
  .cover {
    display: block;
    width: 100%;
    max-width: 640px;
    border-radius: 1.75rem;
    aspect-ratio: 4 / 3;
    object-fit: cover;
    margin-bottom: 1rem;
  }
}
