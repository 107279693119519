.main-banner {
  height: calc(100vh - 6.5rem);
  display: flex;
  color: $font-color-contrast;
  background-position: center center;
  background-size: cover;

  > .banner-text {
    margin: auto;
    text-align: center;

    > h1 {
      color: inherit;
      margin: 1rem 0;
      line-height: 1.5;
      overflow-wrap: anywhere;
    }
  }
}

.campaign {
  padding: 2rem 1rem;
  background: $bg-color-darker;
  > div > h4 {
    margin-bottom: 0;
  }
}

.our-services {
  padding: 3rem 1rem;

  > .row {
    margin: 0;
  }

  > .row > div {
    padding: 1.5rem;
  }

  img {
    height: 7.5rem;
    width: auto;
    margin-bottom: 0.5rem;
  }
}

.service-block {
  padding: 5rem 1rem;
  text-align: center;
}

.service-block h2 {
  overflow-wrap: break-word;
}

.service-block.darker {
  background: $bg-color-darker;
  .container {
    padding: 0;
  }
}
.service-block .req-img {
  width: 70%;
}

.our-work {
  padding: 5rem 1rem;
  background: $bg-color-darker;

  > .container {
    > .carousel-wrap {
      display: flex;
      flex-flow: row nowrap;
      margin: 3em 0;

      > .arrow-left,
      > .arrow-right {
        flex-basis: 6rem;
        color: $primary-color;
        display: flex;
        cursor: pointer;
      }

      > .arrow-left > .fa,
      > .arrow-right > .fa {
        font-size: 3rem;
        margin: auto;
      }

      > .arrow-left:hover > .fa,
      > .arrow-right:hover > .fa {
        color: darken($primary-color, 10%);
      }

      > .carousel {
        width: calc(100% - 6rem);
      }
    }
  }
}

@media (max-width: map-get($grid-breakpoints, "sm")) {
  .our-work {
    > .container {
      > .carousel-wrap {
        > .arrow-left,
        > .arrow-right {
          flex-basis: 3rem;
        }
        > .arrow-left > .fa,
        > .arrow-right > .fa {
          font-size: 2rem;
        }
      }
    }
  }
}

.review {
  text-align: center;

  h3 {
    background-image: url("../images/quote-icon.svg");
    background-repeat: no-repeat;
    background-position: 0 0;
    background-size: 2rem auto;
    padding-top: 1rem;
    padding-left: 2rem;
    margin-bottom: 2rem;
  }

  img {
    width: 5rem;
    margin-bottom: 1rem;
    border-radius: 2.5rem;
  }
}

.we-are-different,
.other-customers,
.who-we-are,
.container.review,
.text-content,
.perks-and-benefits,
.open-positions,
.blog-posts,
.blog-post-header,
.blog-post-content {
  padding: 5rem 1rem;
}

.container.text-content + .container.review,
.container.review + .container.review {
  padding-top: 0;
}

.text-content {
  max-width: 45em;

  h2,
  h3,
  h4 {
    margin-bottom: 1.5rem;
  }

  p + h2,
  p + h3,
  p + h4,
  ul + h2,
  ul + h3,
  ul + h4 {
    margin-top: 4rem;
  }
}

.perks-and-benefits {
  background: $bg-color-darker;
}

.we-are-different,
.who-we-are,
.open-positions,
.perks-and-benefits > .container {
  > .row {
    margin: 0;
  }

  > .row > div {
    padding: 1.5rem;
  }

  .icon {
    height: 3.5rem;
    width: auto;
    margin-bottom: 0.5rem;
  }
}

.open-positions .position-card {
  padding: 3rem;
  border-radius: 0.5rem;
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.3);

  p {
    margin: 1.5rem 0;
  }
}

.other-customers {
  .logos {
    margin: 3rem 0;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;

    img {
      width: 200px;
    }
  }
}

@media (max-width: 992px) {
  .we-are-different,
  .who-we-are,
  .perks-and-benefits {
    text-align: center;
  }
}

.case-block {
  padding: 5rem 1rem;
  color: $font-color-contrast;

  h2,
  h5 {
    color: inherit;
  }

  h2 {
    margin: 1.5rem 0;
  }

  img {
    display: block;
    width: 100%;
    height: auto;
    margin: 0 auto;
  }

  .row {
    margin: 0;
    align-items: center;
  }

  .row > div {
    padding: 1.5rem;
  }
}
