.contact-form {
  padding: 4rem 0;
  background: $primary-color;
  color: $font-color-contrast;

  .container {
    max-width: 37rem;
  }

  h2 {
    text-align: center;
    color: inherit;
    margin-bottom: 2rem;
  }

  label, input[type="text"], input[type="email"], textarea {
    display: block;
    width: 100%;
  }

  label {
    margin: 0;
    line-height: 1;
  }

  input[type="text"], input[type="email"], textarea {
    background: transparent;
    font: inherit;
    color: inherit;
    border: none;
    border-radius: 0;
    border-bottom: 1px solid $font-color-contrast;
    margin-bottom: 2rem;
    line-height: 1.5em;
    padding: 0.25em 0;
  }

  input[type="text"]:focus, input[type="email"]:focus, textarea:focus {
    outline: none;
  }

  .btn:last-child {
    margin-left: 2rem;
  }
}

@media (max-width: 544px) {
  .contact-form {
    .btn {
      width: 100%;
    }
    .btn:last-child {
      margin-left: 0;
      margin-top: 1em;
    }
  }
}


.footer {
  background: $bg-color-darker;

  .container {
    padding: 4rem 0;

    > .row {
      margin: 0;
    }

    > .row > div {
      padding: 1.5rem;
    }
  }

  a {
    color: inherit;
  }

  a:hover {
    color: $primary-color;
  }
  
  .logo {
    display: block;
    height: 2.5rem;
  }
  .logo > img {
    height: 2.5rem;
    width: auto;
  }

  .sub-footer {
    border-top: 2px solid $primary-color;
    margin: 0 1.5rem;
    padding: 1.5rem 0 !important;
  }

  .sub-footer > .social {
    float: right;

    > a {
      margin: 0 0.5rem;
    }
  }
}

@media (max-width: 992px) {
  .footer .sub-footer {
    text-align: center;

    .social {
      display: block;
      float: none;
    }
  }
}

@media (max-width: 544px) {
  .footer .sub-footer {
    margin: 0;
  }
}