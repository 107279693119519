@import url("https://fonts.googleapis.com/css?family=Montserrat:400,700&display=swap");
@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css");
@import "~bootstrap-scss/bootstrap-reboot.scss";
@import "~bootstrap-scss/bootstrap-grid.scss";
@import "~bootstrap-scss/transitions";
@import "~bootstrap-scss/carousel";

$primary-color: #73c377;
$bg-color: #ffffff;
$bg-color-darker: #fafafa;
$font-color: #515151;
$font-color-lead: #293340;
$font-color-contrast: #ffffff;

@import "typography";
@import "buttons";
@import "header";
@import "promo";
@import "footer";
@import "text-variants";
@import "blog";
