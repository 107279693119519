.header {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.nav {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

.nav {
  > .logo > img {
    height: 3.5rem;
    width: auto;
    display: block;
  }

  > .links {
    display: flex;
    flex-flow: row nowrap;
    flex-grow: 1;
    justify-content: center;
  }

  > .menu-btn {
    padding-right: 0;
    border-radius: 0;
    min-width: 0;
    display: none;
  }

  > .links {
    > .nav-list,
    > .lang-list {
      margin: 0;
      padding: 0;
      list-style: none;
      display: flex;
      flex-flow: row nowrap;
      font-weight: bold;
      text-transform: uppercase;
    }

    > .nav-list {
      li > a {
        color: $font-color-lead;
        text-decoration: none;
        display: block;
        padding: 1rem 1.75rem;
        position: relative;
      }
      li > a.active,
      li > a:hover {
        color: $primary-color;
      }
      li > a > span {
        text-transform: none;
        position: absolute;
        font-size: 0.6em;
        top: 0.3rem;
        right: 1.75rem;
        color: $primary-color;
      }
    }

    > .lang-list {
      margin: 0 calc(1.75rem / 2);

      li > a {
        color: transparentize($font-color-lead, .5);
        text-decoration: none;
        display: block;
        padding: 1rem;
        position: relative;
      }
      li > a.active,
      li > a:hover {
        color: $font-color-lead;
      }
      li > a:after {
        display: inline-block;
        content: '.';
        width: 3px;
        position: relative;
        left: 1em;
        color: transparent;
        background: $font-color-lead;
      }
      li:last-child > a:after {
        display: none;
      }
    }
  }
}


@media (max-width: 992px) {
  .header {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
  .nav {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: space-between;

    > .logo {
      order: 1;
      img {
        height: 2.5rem;
      }
    }

    > .btn.btn-outline {
      order: 2;
    }

    > .menu-btn {
      display: block;
      order: 3;
    }

    > .links {
      order: 4;
      width: 100%;
      flex-flow: column nowrap;
      align-items: center;
      text-align: center;

      > .nav-list {
        flex-flow: column nowrap;
      }
    }

    > .links.collapse {
      display: none;
    }

    > .links.collapse.show {
      display: flex;
    }
  }
}

@media (max-width: 768px) {
  .nav .btn.btn-outline {
    display: none;
  }
}