.text-variants {
  display: inline-block;
  vertical-align: bottom;
  text-align: left;
  overflow: hidden;
  position: relative;
  height: 1.5em;
  transition: width 0.3s ease-in-out;


  > .text-variant {
    display: block;
    position: relative;
    transition: top 0.3s ease-in-out;
  }
}

.text-variants.disable-animation {
  > .text-variant {
    transition: none;
  }
}

@media (max-width: map-get($grid-breakpoints, 'md')) {
  .text-variants {
    text-align: center;
  }
}