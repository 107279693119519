.btn {
  display: inline-block;
  position: relative;
  border: 3px solid transparent;
  outline: none;
  background-color: transparent;
  padding: 0.75rem 2rem;
  height: 3rem;
  font: inherit;
  font-size: 0.8em;
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  min-width: 7em;
  border-radius: 1.75rem;
}
a.btn:hover {
  text-decoration: none;
}

.btn-outline {
  border-color: $primary-color;
  color: $font-color-lead;
}
.btn-outline:hover {
  color: $font-color-contrast;
  background-color: $primary-color;
}


.btn-outline-inverse {
  border-width: 1px;
  height: 2.8rem;
  border-color: $font-color-contrast;
  color: $font-color-contrast;
}
.btn-outline-inverse:hover {
  color: $primary-color;
  background-color: $font-color-contrast;
}


.btn-filled {
  border-color: $primary-color;
  background-color: $primary-color;
  color: $font-color-contrast;
}
.btn-filled:hover {
  border-color: darken($primary-color, 10%);
  color: $font-color-contrast;
  background-color: darken($primary-color, 10%);
}
